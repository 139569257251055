import axios from "axios"
import React from "react"

export const NewsDate = (date) => {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d)
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
  return <span>{`${da} ${mo} ${ye}`}</span>
}

export const getIpAddress = (cb) => {
  axios
    .get("https://api.ipify.org/?format=json")
    .then((result) => {
      cb(result.data?.ip)
    })
    .catch((err) => {
      console.error(err)
    })
}

export const goToContact = () => {
  const element = document.querySelector("#register-your-form");
  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        50,
    })
  }
}
export const goToFooterContact = () => {
  const element = document.querySelector("#contact");
  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        50,
    })
  }
}