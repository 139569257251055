import React, {useState} from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'
import loadable from "@loadable/component"
import AppointmentFormFields from "../../../../static/forms/book_appointment.json"
import ContactFormFields from "../../../../static/forms/contact_form.json"

const ReactPlayer = loadable(() => import("react-player"));
const DefaultForm = loadable(() => import("../../forms/default-form-layout"));

const {ContentModule} = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

import './assets/styles/_index.scss'

const CampaignBannerVideo = (props) => {
  const [isMuted, setIsMuted] = useState(true);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const handleToggleMute = () => {
    setIsMuted(!isMuted);
  };
  const handleVideoReady = () => {
    setIsVideoReady(true);
  };
    return (
        <div className='banner-video-wrapper'>
            <Row className='banner-video-row'>
                <Col xs={12} lg={6} className='banner-left-section'>
                    <div className="video-background">
                        <ReactPlayer
                            url={props?.Video_Url}
                            playing={true}
                            loop={true}
                            muted={isMuted}
                            controls={false}
                            autoplay={true}
                            playsinline={true} 
                            onReady={handleVideoReady}
                            width="100%"
                            height="100%"
                            webkitallowfullscreen
                            mozallowfullscreen
                            allowfullscreen
                        />
                        {isVideoReady && (
                            <button onClick={handleToggleMute} className='video-audio-control'>
                                <i class={`${(isMuted) ? 'icon icon-mute-btn' : 'icon icon-unmute-btn'} `}></i>
                            </button>
                        )}
                    </div>
                </Col>
                <Col xs={12} lg={6} className='banner-right-section'>
                    <div className='banner-content'>
                        <h1>{props?.Banner_Title ? props?.Banner_Title : props?.Title}</h1>
                        {props.Banner_Content &&
                            <ContentModule Content={props.Banner_Content?.data?.Banner_Content} />
                        }
                        <div className='banner-form-wrapper'>
                            {props?.Register_Form &&
                                <div className='form-wrapper'>
                                    <h2>Register your Interest</h2>
                                    <p>Share your details and we’ll be in touch with further information about this project.</p>
                                    <DefaultForm fields={ContactFormFields} classname="enquiry-form-wrapper register-interest-form" />
                                </div>
                            }
                            {props?.Book_Appointment_Form &&    
                                <div className='appointment-form-wrapper'>
                                    <h2>Book an Appointment</h2>
                                    <DefaultForm fields={AppointmentFormFields} formName="Rent to Own" classname="enquiry-form-wrapper" />
                                </div>
                            }
                        </div>                        
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default CampaignBannerVideo;