import React, { useState } from 'react'
import PlayVideo from "../PlayVideo/PlayVideo"

import { Col, Container, Row, Carousel } from 'react-bootstrap'
import { Link } from 'gatsby'
import loadable from "@loadable/component"
import ScrollAnimation from 'react-animate-on-scroll'
import ContactFormFields from "../../../../static/forms/contact_form.json"
//import AppointmentFormFields from "../../../../static/forms/book_appointment.json"
import './assets/styles/_index.scss'
import { goToContact } from '../../../common/site/functions'

const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const DefaultForm = loadable(() => import("../../forms/default-form-layout"))

const CampaignBanner = (props) => {
    var imagename = "campaign.banner_section.landing_banner_image";
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms) {
        processedImages = props?.imagetransforms.banner_section_Transforms;
    }

    // Video play
    const [isPlay, setPlay] = useState(false)
    const [videoLink, setVideoLink] = useState('')
    // Video play
    
    return (
        <div className='banner-wrapper'>
            <picture>
                {props?.Header_Banner_Image && props?.Header_Banner_Image.length > 1 &&
                    <Carousel className="banner-img" indicators={false}>
                        {props?.Header_Banner_Image.map((image) => {
                            return (
                                <Carousel.Item>
                                    <img src={image?.url} alt={image.alternativeTex} />
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                }
                {props?.Header_Banner_Image && props?.Header_Banner_Image.length == 1 &&
                    <img src={props.Header_Banner_Image[0]?.url} 
                    alt={props?.Header_Banner_Image[0]?.alternativeTex} className="banner-img" />

                }
            </picture>
            <div className='play-button' onClick={() => { setPlay(true); setVideoLink(props.video_url) }} aria-label='Video Icon'>
                    <i className='icon icon-play-btn' ></i>
            </div>

            <div className="overlay-bg"></div>
            <div className="layout-padding-top"></div>
            <Container>
                <div className='banner-inner'>
                        <Row className='d-flex justify-content-between'>
                            <Col xl={6}>
                                <div className="banner-content-wrapper">
                                    <div className='banner-text-wrapper'>
                                        <div className='banner-content'>
                                            <h1>{props.Banner_Title}</h1>
                                            {props.Content_Heading_Text &&
                                                <p>{props.Content_Heading_Text}</p>
                                            }
                                        </div>

                                        <div className='banner-cta d-xl-none d-flex'>
                                            <a href='javascript:;' className='button button-primary' onClick={() => goToContact()}>Register your Interest</a>
                                        </div>                                        
                                    </div>
                                </div>
                            </Col>
                            <Col xl={4} className='d-xl-block d-none'>
                                <div className='form-wrapper'>
                                    <h2>Register your Interest</h2>                                    
                                    <DefaultForm fields={ContactFormFields} classname="enquiry-form-wrapper" />
                                </div>
                            </Col>
                        </Row>        
                </div>
            </Container>

            {isPlay && videoLink && (
                <PlayVideo
                    isOpen={isPlay}
                    stopPlay={setPlay}
                    videoId=""
                    isCloseFunction={setPlay}
                    videourl={videoLink}
                    htmlink={""}
                />
            )}

        </div>
    )
}

export default CampaignBanner
